<template>
  <div class="inter-wrapper">
    <!-- <HeaderInter />
    <Breadcrumbs back-word="返回" /> -->
    <div class="orderinit-area">
      <div class="social_area">
        <div class="logintop" />
        <div class="social_btn_area text-center">
          <div class="inner_btn_area">
            <span class="title">
              即買即用電子票券，輕鬆享受吃玩買住行
            </span>
            <span class="subtitle">
              立即使用社交平台帳戶登入
            </span>
            <!-- google -->
            <div ref="googlebtn" style="width: 280px;margin: 6px auto;" />
            <!-- FB -->
            <div class="socialbtn" @click="FBlogin()">
              <div class="custombtn">
                <div
                  style="text-align: left;display: flexbox;align-items: center;"
                  class="icon"
                >
                  <img class="icon" src="@/assets/images/fbicon.svg">
                </div>
                <span class="buttonText">使用 Facebook 帳戶登入</span>
              </div>
            </div>
          </div>
          <img
            v-if="logo"
            class="bottonarea"
            :src="logo"
            width="100px"
            height="30px"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginBySSO } from '@/api/user'
import { siteLogin } from '@/utils/user'
import { showNotice } from '@/utils/tool'

export default {
  name: 'LoginIndex',
  components: {},
  data() {
    return {
      googlebtn: null,
      redirecturl: null,
      logo: ''
    }
  },
  created() {
    if (
      this.$store.getters.shopInfo &&
      this.$store.getters.shopInfo.logo !== undefined
    ) {
      this.logo = this.$store.getters.shopInfo.logo2
    }
    this.redirecturl = this.$route.params.redirect
      ? window.atob(this.$route.params.redirect)
      : '/index'
    // init fb
    if (!window.FB) {
      this.fbInit()
    }
    // init google
    this.googleInit()
  },
  mounted() {
    this.$nextTick(() => {
      try {
        // this.googleInit()
        if (window.google) {
          this.googleloginbtnrender()
        } else {
          this.googleInit()
          this.googleloginbtnrender()
        }
      } catch (error) {
        // error
      }
    })
  },
  destroyed() {
    this.$store.commit('SET_LOADING', false)
  },
  methods: {
    fbInit() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId: '572687050485535',
          cookie: true,
          xfbml: true,
          version: 'v14.0'
        })
        window.FB.AppEvents.logPageView()
      }
      ;(function(d, s, id) {
        var js
        var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    },
    googleInit() {
      window.google.accounts.id.initialize({
        client_id:
          '336842712596-eh2b17a0l3qhtvu12j9j0ggd5uige7cv.apps.googleusercontent.com',
        callback: this.googlecallback
      })
    },
    googleloginbtnrender() {
      window.google.accounts.id.renderButton(this.$refs.googlebtn, {
        theme: 'outline',
        size: 'large',
        shape: 'pill',
        logo_alignment: 'left',
        width: '280px'
      })
    },
    doSociallogin(Socialtype, id_token) {
      const self = this
      this.$store.commit('SET_LOADING', true)
      switch (Socialtype) {
        case 'google':
          loginBySSO(id_token, 'google')
            .then(res => {
              self.$store.commit('SET_LOGIN_TYPE', 'google')
              siteLogin(res, self.redirecturl)
            })
            .catch(() => {
              showNotice('Google登入失敗(token) 請重新嘗試')
            })
            .finally(() => {
              this.$store.commit('SET_LOADING', false)
            })
          break
        case 'facebook':
          loginBySSO(id_token, 'facebook')
            .then(res => {
              self.$store.commit('SET_LOGIN_TYPE', 'facebook')
              siteLogin(res, self.redirecturl)
            })
            .catch(() => {
              showNotice('facebook登入失敗(token) 請重新嘗試')
            })
            .finally(() => {
              this.$store.commit('SET_LOADING', false)
            })
          break
        default:
          break
      }
    }, // googlelogin
    Googlelogin() {
      console.log('Googlelogin st', window.google)
      try {
        window.google.accounts.id.prompt(notification => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            // try next provider if OneTap is not displayed or skipped
            window.google.accounts.id.prompt()
          }
        })
      } catch (error) {
        console.log('Googlelogin', error)
      }
    }, // FBlogin
    FBlogin() {
      const self = this
      // 檢查登入狀態
      window.FB.getLoginStatus(function(response) {
        if (response.status !== 'connected') {
          // 登入狀態 - 未登入  用戶登入(確認授權)
          window.FB.login(
            function(res) {
              if (res.status === 'connected') {
                self.doSociallogin('facebook', res.authResponse.accessToken)
              }
            },
            { scope: 'public_profile,email' }
          )
        } else {
          // connected
        }
      })
    },
    googlecallback(res) {
      this.doSociallogin('google', res.credential)
    }
  }
}
</script>
<style scoped lang="scss">
@import 'assets/scss/common.scss';
.orderinit-area {
  min-height: 100vh;
  .social_area {
    padding: 0 !important;
    position: relative;
    .logintop {
      width: 100%;
      height: 400px;
      background: url(~@/assets/images/loginbgimg.svg) no-repeat bottom;
      background-size: cover;
      position: relative;
    }
    .social_btn_area {
      height: calc(100vh - 400px);
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      & > * {
        margin: 0.5rem auto;
      }
      .inner_btn_area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: stretch;
        flex: 3;
        .title {
          margin: 4px 0;
          font-weight: 600;
          font-size: 1rem;
          color: #6a6a6a;
        }
        .subtitle {
          margin: 4px 0;
          margin-top: 0.3rem;
          font-size: 0.7rem;
          color: rgba(#6a6a6a, 0.7);
          font-weight: 600;
        }
        .socialbtn {
          width: 280px;
          height: auto;
          margin: 6px auto;
          border-radius: 50px;
          border: 1px solid rgba($black-color, 0.2);
          background-color: $white-color;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.6s;
          &:hover {
            background-color: rgba(#e9e7e7, 0.8);
            cursor: pointer;
          }
          .custombtn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            .icon {
              flex-basis: 30%;
              padding-left: 2px;
              width: 23px !important;
              height: 23px !important;
            }
            .buttonText {
              font-weight: 500;
              flex-basis: 80%;
              font-size: 0.9rem;
              text-align: left;
            }
          }
        }
      }
      .bottonarea {
        flex: 1;
      }
    }
  }
}

.orderinit-area > .social_area:last-child {
  padding-bottom: 3.5rem;
}

@media screen and (min-width: 768px) {
  .orderinit-area {
    .social_area {
      .logintop {
        height: 650px;
      }
      .social_btn_area {
        height: 100%;
        margin-bottom: 5rem;
        & > * {
          margin: 1rem auto;
        }
        .inner_btn_area {
          .title {
            font-size: 1.2rem;
            font-weight: 600;
          }
          .subtitle {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
</style>
